<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Şehir Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Şehir Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Şehir Listesi",
          href: "/city",
        },
        {
          text: "Şehir Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: {
        city: "",
        plate: "",
        order: "",
      },
    };
  },
  mounted() {
    const itemId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/city/${itemId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.values = response.data?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    showAlertSuccess(){
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger(){
      this.isAlertVisibleDanger = true
    },
    updateItem() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/city/${itemId}`;

      axios
        .put(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class>
          <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
          <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="updateItem">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="order">Şehir Sırası:</label>
                    <input
                      v-model="values.order"
                      type="text"
                      class="form-control"
                      id="order"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="city">Şehir Adı:</label>
                    <input
                      v-model="values.city"
                      type="text"
                      class="form-control"
                      id="city"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="plate">Şehir Plakası:</label>
                    <input
                      v-model="values.plate"
                      type="text"
                      class="form-control"
                      id="plate"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
